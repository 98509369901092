<template>
  <div>
    <ul class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
      <!--begin::Item-->
      <li class="nav-item">
        <button type="button" class="no-shadow btn btn-sm btn-icon btn-bg-light btn-icon-success btn-hover-success" @click="visible = !visible">
          <b-icon icon="droplet-half" variant="success"></b-icon>
        </button>
      </li>
      <!--end::Item-->
    </ul>
    <vue-drawer :visible.sync="visible" :zIndex="9999" placement="right" :width="350">
      <div slot="header">Kustomisasi Warna Tema</div>
      <div slot="body">
        <div class="row">
          <div class="col-12 pl-2 pr-2">
            <label class="text-sm" for="header_background">Header Background</label>
            <input type="color" id="header_background" name="header_background" v-model="color.header_background" @input="setColor('header_background')" />
          </div>
          <div class="col-6 pl-2 pr-2" hidden>
            <label class="text-sm" for="header_font">Header Font</label>
            <input type="color" id="header_font" name="header_font" v-model="color.header_font" @input="setColor('header_font')" />
          </div>

          <div class="col-6 pl-2 pr-2">
            <label class="text-sm" for="sidebar_background">Sidebar Background</label>
            <input type="color" id="sidebar_background" name="sidebar_background" v-model="color.sidebar_background" @input="setColor('sidebar_background')" />
          </div>
          <div class="col-6 pl-2 pr-2">
            <label class="text-sm" for="sidebar_hover">Sidebar Hover</label>
            <input type="color" id="sidebar_hover" name="sidebar_hover" v-model="color.sidebar_hover" @input="setColor('sidebar_hover')" @change="reloadPage()" />
          </div>

          <div class="col-6 pl-2 pr-2">
            <label class="text-sm" for="tab_background">Tab Background</label>
            <input type="color" id="tab_background" name="tab_background" v-model="color.tab_background" @input="setColor('tab_background')" />
          </div>
          <div class="col-6 pl-2 pr-2">
            <label class="text-sm" for="tab_font">Tab Font</label>
            <input type="color" id="tab_font" name="tab_font" v-model="color.tab_font" @input="setColor('tab_font')" />
          </div>

          <div class="col-6 pl-2 pr-2">
            <label class="text-sm" for="tab_active_background">Tab Active Background</label>
            <input type="color" id="tab_active_background" name="tab_active_background" v-model="color.tab_active_background" @input="setColor('tab_active_background')" />
          </div>
          <div class="col-6 pl-2 pr-2">
            <label class="text-sm" for="tab_active_font">Tab Active Font</label>
            <input type="color" id="tab_active_font" name="tab_active_font" v-model="color.tab_active_font" @input="setColor('tab_active_font')" />
          </div>

          <div class="col-6 pl-2 pr-2">
            <label class="text-sm" for="root_background">Root Background</label>
            <input type="color" id="root_background" name="root_background" v-model="color.root_background" @input="setColor('root_background')" />
          </div>
          <div class="col-6 pl-2 pr-2">
            <label class="text-sm" for="root_background">Logo</label>
            <!-- <input type="select" id="root_background" name="root_background" v-model="logo" :options="options_logo" @input="setLogo()" /> -->
            <b-form-select v-model="logo" :options="options_logo" @input="setLogo()"></b-form-select>
          </div>
          <div class="col-6 pl-2 pr-2" hidden>
            <label class="text-sm" for="card_background">Card Background</label>
            <input type="color" id="card_background" name="card_background" v-model="color.card_background" @input="setColor('card_background')" />
          </div>
          <div class="col-12 pl-2 pr-2" v-if="role">
            <label class="text-sm" for="role_options">Role Options</label>
            <b-form-select
              id="role_options"
              v-model="selected_role"
              :options="option_role"
              @input="set_role_config($event)"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_role"
              track-by="ms_role_id"
              placeholder="-- Pilih Role --"
              size="sm"
            ></b-form-select>
          </div>
          <div class="col-12 pl-2 pr-2" v-if="role">
            <b-button class="ml-2" @click="$router.push('/change_password')" variant="primary">Change Password </b-button>
          </div>
        </div>
      </div>
    </vue-drawer>
  </div>
</template>

<script>
export default {
  name: "TheStickyToolBar",
  data() {
    return {
      visible: false,
      color: {
        sidebar_background: "#688A91",
        sidebar_hover: "#6f9f9f",

        header_background: "#FFFFFF",
        header_font: "#E4E7EA",

        tab_background: "#979797",
        tab_font: "#FFFFFF",

        tab_active_background: "#52525B",
        tab_active_font: "#FFFFFF",

        root_background: "#EBEDEF",
        card_background: "#EBEDEF",
      },
      logo: "",
      options_logo: [
        // { value: "logo_depan.png", text: "Healthical" },
        {value: "kudus.png", text: "testing"}
      ],
      option_role: [],
      selected_role: [],
    };
  },
  computed: {
    role_id() {
      return this.$store.state.role_id ? this.$store.state.role_id : null;
    },
    role_name() {
      return this.$store.state.real_role ? this.$store.state.real_role : null;
    },
    role() {
      return this.$store.state.real_role ? this.$store.state.real_role == "super admin" : null;
    },
  },
  watch: {
    role_id: function (newV, oldV) {
      if (newV != oldV) {
        this.get_roles;
      }
    },
  },
  created() {
    this.get_roles();
    // console.log('woekwoekwoekwos')
  },
  mounted() {
    this.color = this.$store.state.color;
    // console.log(this.color);
  },
  methods: {
    setColor(type) {
      this.$store.commit("color", [type, this.color[type]]);
      this.$forceUpdate();
    },
    setLogo() {
      this.$store.commit("set_logo", this.logo);
    },
    reloadPage() {
      window.location.reload();
    },
    async set_role_config(x) {
      this.$store.commit("set_role", x.nama_role);
      this.$store.commit("set_role_id", x.ms_role_id);
      let vm = this;
      let role_akses = await vm.$axios.post("pool_akses/list_kode_akses", {
        nama_role: x.nama_role
      });
      // console.log(role_akses.data.data,'role akses')
      vm.$store.commit("set_all_akses", role_akses.data.data ? role_akses.data.data : []);
      // console.log(role_akses.data.data);
    },
    async get_roles() {
      let vm = this;
      let roles = await vm.$axios.post("ms_role/list");
      // console.log(roles, "roles");
      if (roles.data.status == 200) {
        if (roles.data.message == "sukses") {
          if (roles.data.data.length) {
            vm.option_role = roles.data.data.filter((item) => {
              // console.log(item.nama_role, vm.role_name);
              item.text = item.nama_role.toUpperCase();
              item.value = item;
              if (item.nama_role == vm.role_name) {
                vm.selected_role = item;
                vm.set_role_config(item);
              }
              return this.$store.state.real_role == "super admin" ? item : item.nama_role != "super admin";
            });
          }
        }
      }
    },
  },
};
</script>

<style>
input[type="color"] {
  width: 100%;
}
.text-sm {
  font-size: 11px;
}
.sticky-toolbar {
  width: 46px;
  position: fixed;
  top: 30%;
  right: 0;
  list-style: none;
  margin: 0;
  margin-top: 0px;
  z-index: 95;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-top-left-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
}
</style>
