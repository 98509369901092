<template>
  <CHeader with-subheader>
    <div class="w-100 d-flex justify-content-between align-items-center">
      <div class="d-flex nowrap align-items-center justify-content-start text-left">
        <CToggler
          in-header
          class="ml-3 d-lg-none"
          @click="$store.commit('toggleSidebarMobile')"
        />
        <CToggler
          in-header
          class="ml-3 d-md-down-none"
          @click="$store.commit('toggleSidebarDesktop')"
        />
        <h5 class="m-0 p-0">{{store.puskesmas.nama_puskesmas}}</h5>
      </div>
      <div class="d-flex nowrap align-items-center">
        <p class="m-0 p-0 mr-2 mt-2">{{store.login.username}}</p>
        <!-- <div style="margin-top: 2px;" class="mr-1">Tunda</div> -->
        <b-form-checkbox v-model="tunda" name="check-button" switch/>
        <date-picker
          style="width: 250px;"
          class="mr-3"
          format="dddd, DD MMMM YYYY HH:mm:ss"
          type="datetime"
          v-model="tanggal_sesi"
          :clearable="false"
          @change="onTanggalSesi"
        ></date-picker>
      </div>
    </div>
    <!-- <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo"/>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <CHeaderNavItem class="px-3 c-d-legacy-none">
        <button
          @click="() => $store.commit('toggle', 'darkMode')"
          class="c-header-nav-btn"
        >
          <CIcon v-if="$store.state.darkMode" name="cil-sun"/>
          <CIcon v-else name="cil-moon"/>
        </button>
      </CHeaderNavItem>
      <TheHeaderDropdownNotif/>
      <TheHeaderDropdownTasks/>
      <TheHeaderDropdownMssgs/>
      <TheHeaderDropdownAccnt/>
      <CHeaderNavItem class="px-3">
        <button
          class="c-header-nav-btn"
          @click="$store.commit('toggle', 'asideShow')"
        >
          <CIcon size="lg" name="cil-applications-settings" class="mr-2"/>
        </button>
      </CHeaderNavItem>
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader> -->
  </CHeader>
</template>

<script>
// import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
// import TheHeaderDropdownNotif from './TheHeaderDropdownNotif'
// import TheHeaderDropdownTasks from './TheHeaderDropdownTasks'
// import TheHeaderDropdownMssgs from './TheHeaderDropdownMssgs'

export default {
  name: 'TheHeader',
  // components: {
  //   TheHeaderDropdownAccnt,
  //   TheHeaderDropdownNotif,
  //   TheHeaderDropdownTasks,
  //   TheHeaderDropdownMssgs
  // }
  data() {
    return {
      tanggal_sesi: new Date(),
      tunda: false,
    }
  },
  watch: {
    tanggal_sesi(newVal, oldVal){
      if(newVal != oldVal){
        this.$store.commit("set_tanggal_sesi", newVal);
        // console.log('tanggal_sesi', newVal)
      }
    }
  },
  computed: {
    store(){
      return this.$store.state
    }
  },
  mounted() {
    const vm = this
    // console.log(vm.$store.state)
    let tanggal_sesi = vm.$store.state.tanggal_sesi
    // vm.tanggal_sesi = new Date(tanggal_sesi)
    // console.log('tanggal_sesi', tanggal_sesi)
    if(!tanggal_sesi || vm.$moment(tanggal_sesi).format('LL') != vm.$moment().format('LL')){
      vm.tanggal_sesi = new Date()
    } 
    setInterval(() => {
      if(!vm.tunda){
        vm.tanggal_sesi = new Date()
        // console.log(vm.tanggal_sesi)
      }
    }, 5000);
  },
  methods: {
    onTanggalSesi(){
      const vm = this 
      // console.log('berubah', vm)
      vm.tunda = true
    }
  },
}
</script>
