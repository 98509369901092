<template>
  <div class="container-top-bar pb-3">
    <div v-if="lists.length > 2">
      <Container class="d-flex flex-row flex-wrap" @drop="onDrop" orientation="horizontal" drag-class="draggable" :auto-scroll-enabled="false">
        <Draggable v-for="(item, i) in filteredList" :key="item.path" class="parent-top-bar">
          <div
            class="child d-flex flex-row align-items-center"
            :class="[item.active ? 'active' : '', item.static ? 'static' : 'draggable']"
            v-bind:style="[
              item.active
                ? {
                    'background-color': tab_active_background,
                    color: tab_active_font,
                  }
                : {
                    'background-color': tab_background,
                    color: tab_font,
                  },
            ]"
          >
            <div class="child-detail pt-1 pb-1 pl-2 pr-2 d-flex flex-row align-items-center" @click="item.static == false ? $router.push(item.path) : staticFunction(item.name)">
              <div class="icon" v-if="item.static === false">
                <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path
                    d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                  ></path>
                </svg>
              </div>
              <div class="text">{{ item.name }}</div>
            </div>
            <div class="child-cls pt-1 pb-1 pl-2 pr-2 d-flex flex-row align-items-center" v-on:click="deleteList(i)" v-if="item.static === false">
              <div class="text">X</div>
            </div>
          </div>
        </Draggable>
      </Container>
    </div>
  </div>
</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";
import { applyDrag } from "../store/utils/helpers";

export default {
  name: "TheTopBar",
  components: { Container, Draggable },
  computed: {
    tab_background() {
      return this.$store.state.color.tab_background;
    },
    tab_font() {
      return this.$store.state.color.tab_font;
    },
    tab_active_background() {
      return this.$store.state.color.tab_active_background;
    },
    tab_active_font() {
      return this.$store.state.color.tab_active_font;
    },
    all_url() {
      // return this.$store.state.all_url;
      return this.$store.state.all_url.length ? this.$store.state.all_url : ["/dashboard"];
    },
    store_lists() {
      return this.$store.state.top_lists.length > 2 ? this.$store.state.top_lists : this.lists;
    },
    filteredList() {
      let x = this.store_lists.length < 3 ? this.lists : this.store_lists;
      return x.filter((ele) => {
        return ele.static === true || this.all_url.includes(ele.match);
      });
    },
  },
  data() {
    return {
      currentIndex: 0,
      lists: [
        {
          name: "Tutup Semua",
          path: null,
          match: null,
          static: true,
        },
        {
          name: "Tutup Selain",
          path: null,
          match: null,
          static: true,
        },
      ],
    };
  },
  watch: {
    $route(to, old) {
      // console.log(to, this.all_url.includes(to.path),this.all_url)
      if (to != old) {
        if (this.all_url.includes(to.path)) {
          this.add(to.name, to.fullPath, to.matched[to.matched.length - 1].path);
          let i = 0;
          for (let item of this.lists) {
            if (item.path === to.fullPath) {
              this.currentIndex = i;
            }
            i++;
          }
        }
        // console.log(this.lists);
      }
    },
    lists: function (newV) {
      this.$store.commit("set_top_list", newV);
    },
    all_url: function (newV, oldV) {
      if (newV != oldV) {
        this.newList();
      }
    },
  },
  created() {
    this.lists = this.store_lists;
  },
  mounted() {
    this.add(this.$route.name, this.$route.fullPath, this.$route.matched[this.$route.matched.length - 1].path);
    // this.newList();
  },
  methods: {
    onDrop(dropResult) {
      if (dropResult.addedIndex < 2) {
        return;
      }
      this.lists = applyDrag(this.lists, dropResult);
    },
    newList() {
      // untuk testing di comment
      // this.$router.push(this.filteredList[2].path);
      // this.filtersList();
    },
    async filtersList() {
      let x = this.store_lists.length < 3 ? this.lists : this.store_lists;
      this.lists = await x.filter((ele) => {
        return ele.static === true || this.all_url.includes(ele.match);
      });
    },
    add(name, path, match) {
      let exist = false;

      for (let item of this.lists) {
        if (item.path === path) {
          exist = true;
          item.active = true;
        } else {
          item.active = false;
        }
      }

      if (exist === false) {
        this.lists.push({ name, path, static: false, active: true, match: match });
      }
    },
    deleteList(index) {
      if (index < 2) {
        return;
      }

      // const currentItem = this.lists[this.currentIndex];
      const targetItem = this.lists[index];
      const prevItem = this.lists[index - 1];
      const nextItem = this.lists[index + 1];

      if (this.lists.length === 3) {
        this.all_url.includes("/dashboard") ? this.$router.push("/dashboard") : this.$router.push(this.all_url[0]);
      } else if (targetItem.active === true) {
        if (nextItem?.path) {
          this.$router.push(nextItem.path);
        }
        if (prevItem?.path) {
          this.$router.push(prevItem.path);
        } else {
          this.all_url.includes("/dashboard") ? this.$router.push("/dashboard") : this.$router.push(this.all_url[0]);
        }
      } else if (targetItem.active === false) {
        if (index == 2) {
          if (targetItem.active === true) {
            if (this.lists.length > 3) {
              this.$router.push(this.lists[index + 1].path);
            } else {
              this.all_url.includes("/dashboard") ? this.$router.push("/dashboard") : this.$router.push(this.all_url[0]);
            }
          }
        }
      }

      this.lists.splice(index, 1);
    },
    staticFunction(name) {
      if (name === "Tutup Semua") {
        this.lists.splice(2, 9999);
        this.$router.push(this.all_url[0]);
      } else if (name === "Tutup Selain") {
        this.lists = this.lists.filter((item) => !(item.static === false && item.active === false));
      }
    },
  },
};
</script>

<style scoped>
.container-top-bar {
  gap: 1rem;
  padding-bottom: 2rem;
  padding-right: 15px;
  padding-left: 15px;
}

.parent-top-bar {
  margin-right: 0.25rem;
  padding-top: 0.25rem;
}

.parent-top-bar > .child {
  width: max-content;
  background-color: #979797;
  color: white;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
}

.parent-top-bar > .active {
  background-color: #52525b;
}

.parent-top-bar > .child > .child-detail {
  min-height: 2rem;
  /* background-color: aqua; */
}

.parent-top-bar > .child:hover {
  background-color: #52525b !important;
}

.parent-top-bar > .child > .child-cls {
  min-height: 2rem;
  border-radius: 0px 5px 5px 0px;
  /* background-color: aqua; */
}

.parent-top-bar > .child > .child-detail > .icon:hover {
  cursor: grab;
}
.parent-top-bar > .child > .child-cls:hover {
  background-color: #ff0000;
  cursor: pointer;
}
</style>
